input#locatioN,
input#locatioN:-webkit-autofill,
input#locatioN:-webkit-autofill:focus,
input#locatioN:-webkit-autofill:hover,
input#locatioN:-webkit-autofill:active{
   transition: background-color 600000s 0s, font-size 600000s 0s, color 600000s 0s !important;
   background-color: transparent !important;
    
}

